import styles from './knowledge-hub.module.scss';
import Header from '../../components/header/Header';

const KnowledgeHub = () => {
  return <div className='page-content-container'>
    <Header headerTitle='Knowledge Hub' />
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <p>Doklady o finančných prostriedkoch/majetku (PoF/PoW) preukazujúci pôvod finančných prostriedkov/majetku.</p>

        <p>
          <b>Zákazník (Individual) zasiela:</b><br/>
          Dokumentáciu o pôvode všetkých jeho prostriedkov v kryptomenách, ktorá zahŕňa vlastníctvo jeho účtu a históriu transakcií alebo všetky príslušné potvrdenia o nákupe, výbere alebo výmene.
        </p>

        <div>
          <p>Niekoľko príkladov možných zdrojov prostriedkov v kryptomenách s akceptovanými dokumentami v jednotlivých kategóriách:</p>
          <ol type='1'>
            <li>
              <div>Kryptomeny zakúpené na burzách, brokeroch alebo obchodných platformách: </div>
              <ol type='a'>
                <li>
                  Screenshot alebo výpis z účtu s potvrdením o nákupe a výbere, na ktorých je uvedené ID účtu alebo iný jedinečný identifikátor a všetky podrobnosti o transakcii <b>ALEBO</b>
                </li>
                <li>
                  Účtenky, ktoré poskytujú dôkaz o nákupe (napr. pomocou mimoburzového obchodu) <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Zisky z obchodovania na burzách, brokeroch alebo obchodných platformách</div>
              <ol type='a'>
                <li>
                  Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na účet zákazníka v Českej Sporitelni <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Ťažba kryptomien (Mining)</div>
              <ol type='a'>
                <li>
                  Doklad o vlastníctve zariadení použitých pri ťažbe (napr. fotografie, účty za nákup) <b>A</b>
                </li>
                <li>
                  Screenshot ťažobnej platformy zobrazujúce odmeny, hashrate a počet ťažiarov
                </li>
                <li>
                  Screenshot alebo e-mailové potvrdenia zobrazujúce históriu transakcií z peňaženiek a do peňaženiek so zobrazením adries peňaženiek a hashov transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Staking (Master Nodes) </div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stakingu <b>A</b>
                </li>
                <li>
                  Adresy peňaženiek a ID transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Airdrops</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stávkovania <b>A</b>
                </li>
                <li>
                  Adresy peňaženiek a ID transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Zámena mincí alebo tokenov (Swap)</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií (adresy peňaženiek a ID transakcií), na ktorých sú zobrazené výmeny a výbery smerujúce na účet zákazníka v Českej Sporitelni
                </li>
              </ol>
            </li>

            <li>
              <div>Pôžičkové fondy a fondy likvidity (Lending a Liquidity pools) </div>
              <ol type='a'>
                <li>
                  Screenshot obrazovky s históriou transakcií (adresy peňaženiek a ID transakcií), na ktorých je jasne uvedený veriteľ/skupina likvidity, suma a pôvod vloženého kolaterálu
                </li>
              </ol>
            </li>

            <li>
              <div>Prvotná ponuka mincí (ICO) a predaj tokenov</div>
              <ol type='a'>
                <li>
                  Pre predaj tokenov: Podpísaná zmluva potvrdzujúca platbu v kryptomene
                </li>
                <li>
                  V prípade ICO: Podpísaná zmluva vrátane úplného názvu ICO
                </li>
                <li>
                  História transakcií (screenshot/e-mail) dokumentujúca nákup a výber tokenu (adresy peňaženiek a ID transakcie)
                </li>
                <li>
                  Výpis z bankového účtu, ak sa na investovanie použil fiat
                </li>
              </ol>
            </li>

            <li>
              <div>Príjem zo služieb</div>
              <ol type='a'>
                <li>
                  Faktúry za služby, ktoré boli zaplatené v kryptomenách <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na učet zákazníka v Českej Sporitelni <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <br/>

        <p>
          <b>Zákazník (Company) zasiela:</b><br/>
          Dokumentáciu o pôvode všetkých jeho prostriedkov v kryptomenách, ktorá zahŕňa vlastníctvo jeho účtu a históriu transakcií alebo všetky príslušné potvrdenia o nákupe, výbere alebo výmene.
        </p>

        <div>
          <p>Niekoľko príkladov možných zdrojov prostriedkov v kryptomenách s akceptovanými dokumentami v jednotlivých kategóriách:</p>
          <ol type='1'>

            <li>
              <div>Kryptomeny zakúpené na burzách, brokeroch alebo obchodných platformách:</div>
              <ol type='a'>
                <li>
                  Screenshot alebo výpis z účtu s potvrdením o nákupe a výbere, na ktorých je uvedené ID účtu alebo iný jedinečný identifikátor a všetky podrobnosti o transakcii <b>ALEBO</b>
                </li>
                <li>
                  Účtenky, ktoré poskytujú dôkaz o nákupe (napr. pomocou mimoburzového obchodu) <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Zisky z obchodovania na burzách, brokeroch alebo obchodných platformách </div>
              <ol type='a'>
                <li>
                  Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na účet zákazníka v Českej Sporitelni <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

            <li>
              <div>Ťažba kryptomien (Mining)</div>
              <ol type='a'>
                <li>
                  Doklad o vlastníctve zariadení použitých pri ťažbe (napr. fotografie, účty za nákup) <b>A</b>
                </li>
                <li>
                  Účtenky za nákup ťažobného hardvéru s uvedením adresy kupujúceho a predávajúceho. Hardvér musí byť zaslaný na adresu podniku, ktorá je vlastníkom účtu v Českej Sporitelni na ktorú smerovali prostriedky z transakcie a pre ktorý predkladáte doklad. Ak sa táto adresa medzičasom zmenila, preukážte, že podnik bol v tom čase registrovaný na tejto adrese
                </li>
                <li>
                  Fotografie ťažobného hardvéru a screenshot používaných platforiem a programov
                </li>
                <li>
                  Výpisy prevádzkových nákladov (napr. účty za elektrinu)
                </li>
                <li>
                  Screenshot o príjmoch z ťažby a odmenách
                </li>
                <li>
                  Screenshot procesu transakcií z a do súkromných a firemných peňaženiek
                </li>
                <li>
                  Ak sa obchodovalo aj s mincami z ťažby, je potrebné, aby ste poskytli screenshoty s históriou transakcií, na ktorých je jasne zobrazený príjem na príslušnej burze a meno majiteľa účtu.
                </li>
                <li>
                  Screenshot alebo e-mailové potvrdenia zobrazujúce históriu transakcií z peňaženiek a do peňaženiek so zobrazením adries peňaženiek a hashov transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Staking (Master Nodes)</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stakingu <b>A</b>
                </li>
                <li>
                  Adresy peňaženiek a ID transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Airdrops</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií, na ktorých sú viditeľné vklady, výbery a zisky vrátane úrokovej sadzby a obdobia stávkovania <b>A</b>
                </li>
                <li>
                  Adresy peňaženiek a ID transakcií
                </li>
              </ol>
            </li>

            <li>
              <div>Zámena mincí alebo tokenov (Swap)</div>
              <ol type='a'>
                <li>
                  Screenshot histórie transakcií (adresy peňaženiek a ID transakcií), na ktorých sú zobrazené výmeny a výbery smerujúce na účet zákazníka v Českej Sporitelni
                </li>
              </ol>
            </li>

            <li>
              <div>Pôžičkové fondy a fondy likvidity (Lending a Liquidity pools)</div>
              <ol type='a'>
                <li>
                  Screenshot obrazovky s históriou transakcií (adresy peňaženiek a ID transakcií), na ktorých je jasne uvedený veriteľ/skupina likvidity, suma a pôvod vloženého kolaterálu
                </li>
              </ol>
            </li>

            <li>
              <div>Prvotná ponuka mincí (ICO) a predaj tokenov</div>
              <ol type='a'>
                <li>
                  Pre predaj tokenov: Podpísaná zmluva potvrdzujúca platbu v kryptomene
                </li>
                <li>
                  V prípade ICO: Podpísaná zmluva vrátane úplného názvu ICO
                </li>
                <li>
                  História transakcií (screenshot/e-mail) dokumentujúca nákup a výber tokenu (adresy peňaženiek a ID transakcie)
                </li>
                <li>
                  Výpis z bankového účtu, ak sa na investovanie použil fiat
                </li>
              </ol>
            </li>

            <li>
              <div>Príjem zo služieb </div>
              <ol type='a'>
                <li>
                  Faktúry za služby, ktoré boli zaplatené v kryptomenách s názvom spoločnosti odosielateľa a príjemcu, prípadne ich IČ DPH <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu s úplnou históriou transakcií zobrazujúce zisky dosiahnuté na burzách, brokeroch alebo obchodných platformách a zobrazujúce výbery smerujúce na účet zákazníka v Českej Sporitelni <b>A</b>
                </li>
                <li>
                  Screenshot alebo výpis z účtu burzy zobrazujúce podrobnosti o účte, a meno vlastníka účtu
                </li>
              </ol>
            </li>

          </ol>
        </div>
      </div>
    </div>
  </div>;
};

export default KnowledgeHub;
