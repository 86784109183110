import Header from 'components/header/Header';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from 'context/user/UserContext';
import axios from 'axios';
import { getStaticPath, axiosErrorHandler } from 'services/api/axios';
import { Formik } from 'formik';
import { confirmChangeCurrentPasswordSchema } from 'components/AuthForms/validationSchema';
import { useNavigate } from 'react-router-dom';
import appRoutes from 'routes/routes';
import DeleteAccountModal from 'components/modals/delete-modals/DeleteAccountModal';
import MainButton from 'components/buttons/MainButton';
import { ReactComponent as BoltGreen } from 'assets/images/svg/boltGreenIcon.svg';
import { ReactComponent as Trash } from 'assets/images/svg/trash.svg';
import useNotification from 'components/notification/UseNotification';
import { useRoleBasedRedirect } from '../../hooks';
import Loader from '../../components/loaders/loader/Loader';

const MyAccount = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amlDemoProcessed, setAmlDemoProcessed] = useState(false);
  const { userEmail, getConfig } = useContext(UserContext);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { home } = appRoutes;
  const showNotification = useNotification();
  const roleBasedRedirect = useRoleBasedRedirect();

  const changePassword = async (values) => {
    setError(null);
    try {
      const res = await axios.patch(getStaticPath('CLIENT_URL', 'change_pw' ),
        {
          old_password: values.currentPassword,
          new_password: values.newPassword,
        });
      if (res.status === 200) {
        showNotification('passwordChange', 'success');
        setError(null);
        navigate(home.dashboard);
      }
    } catch (error) {
      setError(axiosErrorHandler(error, 'Changing the password failed'));
    }
  };

  useEffect(() => {
    if (getConfig('is_aml_demo')) {
      roleBasedRedirect();
    } else {
      setAmlDemoProcessed(true);
    }
  });

  if (!amlDemoProcessed) {
    return <Loader />;
  }

  return (
    <>
      <div className='page-content-container'>
        {isModalOpen && <DeleteAccountModal handleClose={() => setIsModalOpen(false)}/>}
        <Header headerTitle='My account'/>
        <div className='my-account-page-content'>
          <div className='my-account-container'>
            <div className='name-form-container'>
              <p className='form-title'>ACCOUNT EMAIL</p>
              <p className='form-description'>
                {userEmail}
              </p>
            </div>
            <div className='divider'/>
            <div className='password-form-container'>
              <p className='form-title'>ACCOUNT PASSWORD</p>
              <Formik
                initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }}
                validationSchema={confirmChangeCurrentPasswordSchema}
                onSubmit={changePassword}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, }) => (
                  <form onSubmit={handleSubmit} className='main-form'>
                    <input
                      type='password'
                      name='currentPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentPassword}
                      placeholder='Current password'
                      className='main-form-input'
                    />
                    {errors.currentPassword && touched.currentPassword && (
                      <p className='input-alert'>{errors.currentPassword}</p>
                    )}
                    <input
                      type='password'
                      name='newPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                      placeholder='New password'
                      className='main-form-input'
                    />
                    {errors.newPassword && touched.newPassword && (
                      <p className='input-alert'>{errors.newPassword}</p>
                    )}
                    <input
                      type='password'
                      name='confirmPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      placeholder='Confirm password'
                      className='main-form-input'
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <p className='input-alert'>
                        {errors.confirmPassword}
                      </p>
                    )}
                    <div className='input-alert'>{error}</div>
                    <MainButton
                      type='submit'
                      variant='button-purple'
                      label='SAVE CHANGES'
                      icon={<BoltGreen />}
                    />
                  </form>
                )}
              </Formik>
            </div>
            <div className='divider'/>
            <div className='delete-account-container'>
              <p className='form-title'>
								Delete account
              </p>
              <p className='form-description'>
								Please be advised that deleting this account will result in permanent loss of all data.
              </p>
              <MainButton
                onClick={() => setIsModalOpen(true)}
                variant='button-error'
                label='DELETE ACCOUNT'
                icon={<Trash />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
